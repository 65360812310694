import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasAllGroups',
})
export class HasAllGroupsPipe implements PipeTransform {
  transform(user: any, groups: Array<string>): boolean {
    if (!user) return false;
    let ret = true;
    groups.forEach((g) => {
      ret = ret && user.groups.filter((e) => e.name == g).length > 0;
    });
    return ret;
  }
}
